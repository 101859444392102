<template>
	<!--伦理上会 手机端投票登录-->
	<div class="ethical-vote-in-wrap">
		<Spin fix v-if="loading"></Spin>
		<div class="vote-con">
			<van-form @submit="handleSubmit">
				<van-field
					readonly
					clickable
					label="请选择您的身份"
					name="userName"
					:value="userName"
					placeholder="请选择您的身份"
					:rules="[{ required: true, message: '请选择您的身份' }]"
					@click="showPicker = true"
				/>
				<van-popup v-model="showPicker" position="bottom">
					<van-picker
						title="投票人"
						show-toolbar
						:columns="ethicOptions"
						@confirm="onConfirm"
						@cancel="showPicker = false"
					/>
				</van-popup>
				<div style="margin: 100px">
					<van-button round block type="primary" native-type="submit">
						登入
					</van-button>
				</div>
			</van-form>
		</div>
	</div>
</template>

<script>
import api from "@/api/ethicalMeeting/index"
import moment from "moment"
import { getParameterByName } from "@/utils/util"
import { Button, Picker, Field, Popup, Form, Toast } from "vant"
import "vant/lib/picker/style"
import "vant/lib/field/style"
import "vant/lib/popup/style"
import "vant/lib/button/style"
import "vant/lib/form/style"
import "vant/lib/dialog/style"
import "vant/lib/calendar/style"
import "vant/lib/radio-group/style"
import "vant/lib/radio/style"
import "vant/lib/tab/style"
import "vant/lib/tabs/style"

const { mobilePartList, ethicalMobileLogin } = api

export default {
	name: "ethicalMobileVote",
	components: {
		VanButton: Button,
		VanPicker: Picker,
		VanField: Field,
		VanPopup: Popup,
		VanForm: Form
	},
	data() {
		return {
			loading: false,
			ethicOptions: [],
			showPicker: false,
			userName: "",
			userId: "",
			meetId: ""
		}
	},
	mounted() {
		this.meetId = this.$route.query.meetId || getParameterByName("id")
		this.getEthics()
	},
	methods: {
		// 参会人员
		async getEthics() {
			const res = await mobilePartList(this.meetId)
			if (res) {
				this.ethicOptions = res.data.map(item => ({
					text: `${item.participantName}${item.loginStatus ? "(已登录)" : ""}`,
					disabled: item.loginStatus,
					participantId: item.participantId
				}))
			}
		},
		//  关闭 弹层，触发。1,投票人，2，投票意见
		onConfirm(value) {
			this.userName = value.text
			this.userId = value.participantId
			this.showPicker = false
		},
		handleSubmit(values) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await ethicalMobileLogin({
					ethicMeetingId: this.meetId,
					participantId: this.userId
				})
				if (res) {
					Toast.success("登入成功")
					sessionStorage.setItem("mobileVoteInfo", JSON.stringify(res.data))
					this.$router.push({
						name: "mobileEthicalVote",
						query: {
							meetId: this.meetId,
							userId: this.userId
						}
					})
				} else {
					this.getEthics()
				}
				this.loading = false
			})
		}
	}
}
</script>

<style lang="less">
body {
	min-width: 0;
}
.ethical-vote-in-wrap {
	position: relative;
	z-index: 2;
	overflow: auto;
	width: 100vw;
	height: 100vh;
	background: #fefdf8 url("../../../assets/mobileimages/bg-sign.png") no-repeat;
	background-size: 100% 100%;
	.vote-con {
		padding: 50% 25px 80px;
	}
	.van-cell {
		background-color: transparent;
	}
	.van-field__label {
		width: 7em;
	}
	.van-cell__value {
		text-align: left;
	}
	.van-picker__confirm {
		color: #2d8cf0;
	}
	.vote-btn {
		position: fixed;
		bottom: 10px;
		left: 0;
		right: 0;
	}
	.van-button--primary {
		//background: linear-gradient(to right, #fe834b, #fda21e);
		background: #1c2730;
		border-color: #2d8cf0;
	}
	.van-field--error .van-field__control::placeholder {
		color: #c5c8ce;
	}
}
</style>
