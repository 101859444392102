<template>
  <!--按项目查阅-->
  <div class="content-wrap">
    <Spin fix v-if="loading"></Spin>
    <CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
      <CtmsSearchBoxItem :span="1" label="项目名称">
        <Input
          placeholder="请输入项目名称"
          v-model="searchParams.projectName"
        />
      </CtmsSearchBoxItem>
    </CtmsSearchBox>
    <CtmsAction>
      <Button
        @click="exportData"
        v-if="
					systemActionPermissions.indexOf('btn_research_project_export') > -1
				"
      >
        导出
      </Button>
    </CtmsAction>
    <CtmsDataGrid
      :height="dataGridHeight"
      :columns="allColumns"
      :data="listData"
      :page="page"
      ref="table"
    >
      <template slot-scope="{ row }" slot="projectName">
        <a
          v-if="row.hasProjectPower === 1"
          @click="toProject(row)"
          href="javascript:;"
        >{{ row.projectName }}</a
        >
        <span v-else>{{ row.projectName }}</span>
      </template>
    </CtmsDataGrid>
    <ViewDetail
      :visible="viewVisible"
      @onCancel="viewVisible = false"
      :updateId="updateId"
    >
    </ViewDetail>
  </div>
</template>
<script>
import { mapState } from "vuex"
import moment from "moment"
import api from "@/api/achievement/achievement"
import ViewDetail from "./ViewDetail.vue"

const { getResearchListProject, apiGetFormConfigList } = api
export default {
  components: {
    ViewDetail
  },
  data() {
    return {
      loading: false,
      page: {
        current: 1,
        pageSize: 10,
        on: {
          onChange: this.onChange,
          onPageSizeChange: this.onPageSizeChange
        },
        total: 0
      },
      updateId: "",
      viewVisible: false,
      listData: [],
      headers: [],
      columns: [
        {
          title: "项目名称",
          key: "projectName",
          minWidth: 120,
          slot: "projectName"
        }
      ],
      actionColumn: {
        title: "操作",
        key: "action",
        width: 120,
        fixed: "right",
        renderButton: params => {
          const btnList = [
            {
              label: "查看",
              on: {
                click: this.handleView
              }
            }
          ]
          if (
            this.systemActionPermissions.indexOf(
              "btn_research_project_view"
            ) === -1
          ) {
            btnList[0] = null
          }
          return btnList.filter(item => !!item)
        }
      },
      searchParams: {
        projectName: ""
      },
      oldSearchParams: {},
      configList: []
    }
  },
  computed: {
    ...mapState({
      dataGridHeight(state) {
        return state.contentHeight - 130
      },
      ...mapState("permission", ["systemActionPermissions"])
    }),
    allColumns() {
      return [...this.headers, this.actionColumn]
    }
  },
  created() {
    this.getHeader()
  },
  methods: {
    async getHeader() {
      const res = await apiGetFormConfigList()
      if (res && res.data) {
        this.configList = res.data || []
        this.headers = [...this.columns]
        this.configList.forEach(item => {
          const itemNew = {
            title: item.name,
            key: item.formId.toString(),
            minWidth: 120,
            align: "center",
            render: (h, params) => {
              // Count the number of filled IDs that match this item's formId
              const filledIds = params.row.templateIds ? params.row.templateIds.split(",") : [];
              const matchCount = filledIds.includes(item.formId.toString()) ? filledIds.filter(id => id === item.formId.toString()).length : 0;
              
              return h("div", [
                h("span", {
                  style: {
                    fontSize: "15px",
                    display: "inline-block",
                    minWidth: "20px",
                    height: "20px",
                    padding: "0 5px",
                    borderRadius: "3px",
                    color: "#fff",
                    lineHeight: "20px",
                    textAlign: "center",
                    background: params.row[item.formId.toString()]
                      ? "#43bf78"
                      : "#9e9e9e"
                  }
                  // props: {
                  //   type: params.row[item.formId.toString()]
                  //     ? 'ios-checkmark'
                  //     : 'ios-close',
                  // },
                }, matchCount > 0 ? matchCount.toString() : 0)
              ]);
            }
          }
          this.headers.push(itemNew)
        })
        this.initList("init")
      }
    },

    // 获取数据
    async initList(type) {
      this.loading = true
      if (type === "init") {
        const paramsStr = sessionStorage.getItem("achieveParams")
        if (paramsStr) {
          const { params, page } = JSON.parse(paramsStr)
          this.oldSearchParams = { ...params }
          this.searchParams = {
            ...this.searchParams,
            ...params
          }
          this.page.current = page.current
          this.page.pageSize = page.pageSize
        }
        sessionStorage.setItem("achieveParams", "")
      }
      const res = await getResearchListProject({
        ...this.oldSearchParams,
        pageNum: this.page.current,
        pageSize: this.page.pageSize
      })
      if (res && res.data) {
        const list = res.data.list || []
        this.listData = list.map(item => {
          const ids = this.configList.map(child => child.formId.toString())
          const obj = { ...item }
          const filledIds = item.templateIds ? item.templateIds.split(",") : []
          ids.forEach(id => {
            if (filledIds.includes(id)) {
              obj[id] = true
            } else {
              obj[id] = false
            }
          })
          return obj
        })
        this.page.total = res.data.total
      }
      this.loading = false
    },
    // 查看
    handleView({ row }) {
      this.updateId = row.projectId
      this.viewVisible = true
    },
    // 跳转到项目工作台
    toProject(row) {
      sessionStorage.setItem(
        "achieveParams",
        JSON.stringify({
          params: {
            ...this.oldSearchParams
          },
          page: {
            current: this.page.current,
            pageSize: this.page.pageSize
          }
        })
      )
      window.history.pushState(
        {
          ...this.oldSearchParams,
          pageNum: this.page.current,
          pageSize: this.page.pageSize
        },
        null,
        window.location.href
      )
      this.$router.push({
        name: "projectWorkbench",
        params: {
          projectId: row.projectId
        }
      })
    },
    onChange(current) {
      this.page.current = current
      this.$nextTick(() => {
        this.initList()
      })
    },
    onPageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.page.current = 1
      this.$nextTick(() => {
        this.initList()
      })
    },
    searchHandle() {
      this.page.current = 1
      this.oldSearchParams = Object.assign({}, this.searchParams)
      this.$nextTick(() => {
        this.initList()
      })
    },
    // 重置
    reset() {
      this.searchParams = {
        projectName: ""
      }
      this.searchHandle()
    },
    // 导出项目信息
    exportData() {
      let url = `${this.$baseUrl}/common-form/research-project-export?token=${this.$store.state.user.token}`
      const { projectName } = this.oldSearchParams
      url += `&projectName=${projectName || ""}`
      window.open(url)
    }
  }
}
</script>
