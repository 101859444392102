<template>
	<div class="content-wrap-fill-form">
		<!--合同管理-->
		<Spin fix v-if="loading"></Spin>
		<CtmsAction>
			<Button
				v-if="isPermission('btn_project_contract')"
				type="primary"
				class="ivu-btn-right"
				@click="handleAddShow"
				icon="md-add"
				>新增研究成果</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="false"
			ref="table"
		>
		</CtmsDataGrid>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:departList="departList"
			:projectId="projectId"
			:projectInfo="projectInfo"
			:entry="entry"
		></Add>
		<FillFormDetails
			@onCancel="fillFormDetailsVisible = false"
			:visible="fillFormDetailsVisible"
			:isDisabled="fillFormDetailsIsDisabled"
			:templateId="templateId"
			:formDataId="formDataId"
      :formJson="formJson"
      :templateName="templateName"
			@onOk="handleAddFillFormDetailsOk"
		>
		</FillFormDetails>
	</div>
</template>

<script>
import api from "@/api/project/contract"
import achievementApi from "@/api/achievement/achievement"
import { mapState } from "vuex"
import Add from "./Add.vue"
import FillFormDetails from "./FillFormDetails.vue"

const { apiGetContractList, deleteContract, apiGetDeparts, apiGetProDetail } =
	api
const { getResResultList, getDelResResResult, apiResResultPublish } = achievementApi

export default {
	name: "index",
	components: {
		Add,
		FillFormDetails
	},
	props: {
		updateId: {
			type: [String, Number],
			default: null
		}
	},
	data() {
		return {
			addVisible: false,
			fillFormDetailsVisible: false,
			fillFormDetailsIsDisabled: false,
			entry: "",
			templateId: 0,
      formDataId: 0,
      formJson: null,
			templateName: "",
			// 科室
			departList: [],
			// 项目基本信息
			projectInfo: {},
			listData: [],
			columns: [
				{
					title: "研究成果类型",
					key: "templateName",
					minWidth: 180
				},
				{
					title: "填写时间",
					key: "updatedDt",
					minWidth: 180
				},
				{
					title: "填写人",
					key: "updatedBy",
					minWidth: 100
				},
				{
					title: "填写状态",
					key: "isDraft",
					minWidth: 80,
          render: (h, params) =>
            h("span", {}, params.row.isDraft === 1 ? "暂存草稿" : "已提交")
				},
				{
					title: "最后修改时间",
					key: "updatedDt",
					minWidth: 180
				},
				{
					title: "操作",
					key: "action",
					width: 80,
					fixed: "right",
					renderButton: ({ row }) => {
						// Ensure we only return the view button
						return [
							{
								label: "查看",
								on: {
									click: this.handleViewShow
								}
							}
						]
					}
				}
			],
			loading: false
		}
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 160
			}
		}),
		...mapState("permission", ["projectActionPermissions"]),
		projectId() {
			// 如果 updateId 存在，则使用 updateId，否则使用路由参数中的 projectId
			return this.updateId || this.$route.params.projectId
		}
	},
	created() {
		this.initList()
		this.getPublicData()
	},
	methods: {
		getPublicData() {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data || []
				}
			})
			// 获取项目基本信息
			this.$asyncDo(async () => {
				const res = await apiGetProDetail(this.projectId)
				if (res) {
					this.projectInfo = res.data || {}
				}
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getResResultList({ projectId: this.projectId })
				if (res) {
					// Make sure to properly filter out drafts (isDraft === 1)
					this.listData = (res.data || []).filter(item => item.isDraft === 0);
				}
				this.loading = false
			})
		},
		// 新增显示
		handleAddShow() {
			this.entry = "add"
			this.addVisible = true
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.formDataId = row.id
			this.templateId = row.templateId
      this.formJson = row.formJson
			this.templateName = row.templateName
			this.fillFormDetailsVisible = true
			this.fillFormDetailsIsDisabled = false
		},
		// 查看
		handleViewShow({ row }) {
			this.formDataId = row.id
			this.templateId = row.templateId
      this.formJson = row.formJson
			this.templateName = row.templateName
			this.fillFormDetailsVisible = true
			this.fillFormDetailsIsDisabled = true
		},
		// 修改弹窗回调
		handleAddFillFormDetailsOk() {
			this.fillFormDetailsVisible = false
			this.initList()
		},
		// 删除
		handleDelResResResult({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getDelResResResult({
					projectId: row.projectId,
					formDataId: row.id
				})
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 发布草稿
		handleResResultPublish({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiResResultPublish({
					projectId: row.projectId,
					formDataId: row.id
				})
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		isPermission(code) {
			return this.projectActionPermissions.indexOf(code) > -1
		}
	}
}
</script>
<style lang="less">
.content-wrap-fill-form {
  .ctms-action {
    display: none;
  }
}
</style>
